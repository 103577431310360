import React from "react";

const SubPublishing = ({ content }) => {
  return (
    <div className="mt-20">
      <div className="md:flex md:flex-wrap xxl:bg-red-200">
        <div className="md:w-5/12 md:m-auto">
          <h1 className="text-4xl font-medium mb-3">
            {content.subpublishing.title}
          </h1>
          <hr className="mb-3 w-24" />
          <h2 className="text-2xl font-medium mb-10">
            {content.subpublishing.subTitle}
          </h2>
          <div>
            {content.subpublishing.text.map((item, index) => {
              return (
                <div key={index} className="mt-5 text-justify">
                  <p>{item}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="md:w-5/12 md:m-auto">
          <img className="mt-10" src={content.subpublishing.img} alt="icons" />
        </div>
        <div className="mt-10 md:w-5/12 md:m-auto md:mt-20">
          <h3 className="text-3xl font-medium mb-3">
            {content.subpublishing.block1Title}
          </h3>
          {content.subpublishing.block1Text.map((item, index) => {
            return (
              <div key={index} className="mt-5 text-justify">
                <p>{item}</p>
              </div>
            );
          })}
        </div>
        <div className="mt-10 text-right md:w-5/12 md:m-auto md:-mt-20 xl:-mt-5">
          <h4 className="text-3xl font-medium mb-3">
            {content.subpublishing.block2Title}
          </h4>
          <div className="lg:flex">
            {content.subpublishing.block2Cat.map((item, index) => {
              return (
                <div key={index} className="lg:mx-auto lg:w-4/12">
                  <img
                    className="w-16 mt-10 mb-3 ml-auto mr-0"
                    src={item.icon}
                    alt="icons"
                  />
                  <p className="xl:w-9/12 xl:ml-auto xl:mr-0">{item.text}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <hr className="hidden md:block md:w-2/3 md:mx-auto md:mt-20" />
      <div className="md:flex md:flex-wrap md:mt-20">
        <div className="mt-10 md:w-5/12 md:m-auto">
          <h5 className="text-3xl font-medium mb-3">
            {content.subpublishing.block3Title}
          </h5>
          <p>{content.subpublishing.block3Text}</p>
          <div className="xl:flex xl:flex-wrap">
            {content.subpublishing.block3Cat.map((item, index) => {
              return (
                <div key={index} className="xl:w-5/12 xl:mx-auto xl:mt-10">
                  <img
                    className="w-16 mt-10 mb-5"
                    src={item.icon}
                    alt="icons"
                  />
                  <p className="">{item.text}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="mt-10 md:w-5/12 md:m-auto">
          <img className="" src={content.subpublishing.block3Img} alt="icons" />
        </div>
      </div>
    </div>
  );
};

export default SubPublishing;
