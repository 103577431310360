import React from "react";

import ArtistCarousel from "./ArtistCarousel";
import SubPublishing from "./SubPublishing";

const Publishing = ({ content }) => {
  return (
    <div
      id="publishing"
      style={{ background: "#EEEEEE" }}
      className="w-full text-black"
    >
      <div className="p-12 py-24 xl:px-64">
        <div className="md:flex">
          <div className="md:w-5/12 md:m-auto">
            <h1 className="text-5xl font-medium mb-3">
              {content.publishing.title}
            </h1>
            <hr className="mb-3 w-24" />
            <h2 className="text-3xl font-medium mb-10">
              {content.publishing.subTitle}
            </h2>
            <div>
              {content.publishing.text.map((item, index) => {
                return (
                  <p key={index} className="mb-5 text-justify">
                    {item}
                  </p>
                );
              })}
              <h3 className="text-2xl font-medium my-10">
                {content.publishing.subSubTitle}
              </h3>
              <p className="mb-5 text-justify">
                {content.publishing.subSubtext}
              </p>
            </div>
            <div className="mt-10 md:w-boxed md:flex md:flex-wrap md:mt-16 md:ml-8 lg:w-full lg:mt-10 lg:ml-0">
              {content.publishing.cat.map((item, index) => {
                return (
                  <div key={index} className="md:m-auto md:w-5/12">
                    <img className="w-16 mt-10" src={item.icon} alt="icons" />
                    <p className="text-3xl font-medium mb-5 xl:text-xl">
                      {item.text}
                    </p>
                    <hr className="w-20" />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="m-auto mt-10 w-9/12 md:w-5/12 md:mx-auto md:mt-40">
            <img className="" src={content.publishing.img} alt="icons" />
          </div>
        </div>
        <div className="mt-20 w-full hidden lg:block">
          <ArtistCarousel csp={34} itemNb={0} content={content} nb={1} />
        </div>
        <div className="mt-20 w-full lg:hidden">
          <ArtistCarousel csp={100} itemNb={0} content={content} nb={1} />
        </div>
        <SubPublishing content={content} />
        <div className="flex flex-col lg:flex-row">
          <div
            className="w-full lg:w-4/12 m-auto mt-20"
            style={{
              minHeight: "327px",
              position: "relative",
              height: "0",
              paddingTop: "50%",
              paddingBottom: "7px",
              boxShadow: "0 2px 8px 0 rgba(63,69,81,0.16)",
              overflow: "hidden",
              borderRadius: "8px",
              willChange: "transform",
            }}
          >
            <iframe
              title="canva"
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: "0",
                left: "0",
                border: "none",
                padding: "0",
                margin: "0",
              }}
              src={content.publishing.canvaFirstLink}
            ></iframe>
          </div>
          <div className="lg:w-1/2 mt-16 flex lg:p-40">
            <a
              className="m-auto"
              href={content.publishing.canvaSecondLink}
              target="_blank"
              rel="noreferrer"
            >
              {content.publishing.canvaText}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Publishing;
