import React from "react";
import ReactPlayer from "react-player/youtube";
import { Carousel } from "react-responsive-carousel";

import "react-responsive-carousel/lib/styles/carousel.min.css";

const PubCarousel = ({ content, csp, itemNb }) => {
  return (
    <Carousel
      autoPlay={true}
      infiniteLoop={true}
      showStatus={false}
      showIndicators={false}
      showThumbs={false}
      centerMode={true}
      centerSlidePercentage={csp}
      selectedItem={itemNb}
      renderArrowPrev={(onClickHandler, hasPrev) =>
        hasPrev && (
          <button
            aria-label="Previous"
            type="button"
            onClick={onClickHandler}
            className="absolute z-10 bottom-0 right-0 mb-1 mr-16 md:mr-20 lg:mr-32 xl:mr-40 outline-none focus:outline-none rounded-full text-2xl
              transform transition duration-300 hover:scale-110"
          >
            <i className="fas fa-chevron-left"></i>
          </button>
        )
      }
      renderArrowNext={(onClickHandler, hasNext) =>
        hasNext && (
          <button
            aria-label="Next"
            type="button"
            onClick={onClickHandler}
            className="float-right z-10 mb-1 mr-10 md:mr-12 lg:mr-24 xl:mr-32 outline-none focus:outline-none rounded-full text-2xl
              transform transition duration-300 hover:scale-110"
          >
            <i className="fas fa-chevron-right"></i>
          </button>
        )
      }
    >
      {content.sync.media2.map((item, index) => {
        return (
          <a key={index} href={item.url} target="_blank" rel="noreferrer">
            <div
              style={{
                width: "calc(100% - 80px)",
                height: "calc(100% - 40px)",
                margin: "0 40px 40px",
                maxHeight: "490px",
              }}
            >
              <img
                className="object-cover w-full h-full"
                src={item.img}
                alt="icons"
              />
            </div>
          </a>
        );
      })}
      {content.sync.media.map((item, index) => {
        return (
          <div
            key={index}
            style={{
              width: "calc(100% - 80px)",
              height: "calc(100% - 40px)",
              margin: "0 40px 40px",
              maxHeight: "490px",
            }}
          >
            <ReactPlayer
              width="100%"
              height="100%"
              light={true}
              url={item}
              controls
            />
          </div>
        );
      })}
      {content.sync.media3.map((item, index) => (
        <div
          style={{
            width: "calc(100% - 80px)",
            height: "calc(100% - 40px)",
            margin: "0 40px 40px",
          }}
          key={index}
        >
          <iframe
            src={item}
            title="oui"
            width="100%"
            height="100%"
            allow="autoplay; fullscreen; picture-in-picture"
          ></iframe>
        </div>
      ))}
    </Carousel>
  );
};

export default PubCarousel;
