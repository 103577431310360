import React from "react";

import StudioCar from "./StudioCar";

const Studio = ({ content }) => {
  return (
    <div
      id="studio"
      style={{ background: "#EEEEEE" }}
      className="w-full text-black"
    >
      <div className="p-12 pt-24 xl:px-64">
        <div className="md:flex">
          <div className="flex flex-col md:w-5/12 md:m-auto">
            <div className="md:mt-24 lg:mt-0">
              <h1 className="text-5xl font-medium mb-3">
                {content.studio.title}
              </h1>
              <hr className="mb-3 w-24" />
              <h2 className="text-3xl font-medium mb-10">
                {content.studio.subTitle}
              </h2>
              <p className="text-justify">{content.studio.text}</p>
            </div>
            <div className="mx-auto md:flex md:flex-wrap md:mt-40 md:ml-8 lg:mt-10 md:w-boxed lg:w-full xl:w-2/3 lg:m-auto">
              {content.studio.cat.map((item, index) => {
                return (
                  <div key={index} className="md:m-auto">
                    <img
                      className="w-16 lg:w-20 mt-10 mb-5"
                      src={item.icon}
                      alt="icons"
                    />
                    <p className="text-xl font-medium mb-5 md:w-40">
                      {item.text}
                    </p>
                    <hr className="w-20" />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="m-auto mt-10 w-9/12 md:w-5/12 md:-mt-5">
            <img
              className="mt-10 md:mt-0"
              src={content.studio.img}
              alt="icons"
            />
          </div>
        </div>
        <hr className="mt-20" />
        <div className="mt-20">
          <div className="md:m-auto">
            <h1 className="text-4xl font-medium mb-3">
              {content.studio.subSubTitle}
            </h1>
            <p className="text-justify mb-10 xl:mb-5">
              {content.studio.subSubtext}
            </p>
          </div>
        </div>
      </div>
      <StudioCar content={content} />
    </div>
  );
};

export default Studio;
