import React from "react";
import {Store} from "react-notifications-component";
import { sendForm, init } from "@emailjs/browser";

const ContactForm = ({content}) => {
  const sendEmail = (e) => {

    init({
      publicKey: process.env.REACT_APP_PUBLIC_KEY_EMAIL_JS
    });

    e.preventDefault();
    sendForm(
       process.env.REACT_APP_SERVICE_ID,
       process.env.REACT_APP_TEMPLATE_ID,
       e.target,
    )
       .then(
          () => {
            Store.addNotification({
              title: content.contactFormSuccess.title,
              message: content.contactFormSuccess.message,
              type: "success",
              insert: "bottom",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 3000,
                onScreen: true,
              },
            });
          },
          (error) => {
            console.log('\n error : \n',error);
            Store.addNotification({
              title: content.contactFormError.title,
              message: content.contactFormError.message,
              type: "danger",
              insert: "bottom",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 3000,
                onScreen: true,
              },
            });
          }
       );
  };

  return (
     <>
       <form onSubmit={sendEmail} className="flex flex-col">
         <label htmlFor="nameInput" className="font-medium">
           {content.contact.label[0]}
         </label>
         <input
            id="nameInput"
            className="rounded-md mb-5 p-3 border-2"
            type="text"
            name="name"
            required
         />
         <label htmlFor="emailInput" className="font-medium">
           {content.contact.label[1]}
         </label>
         <input
            id="emailInput"
            className="rounded-md mb-5 p-3 border-2"
            type="email"
            name="email"
            required
         />
         <label htmlFor="requestInput" className="font-medium">
           {content.contact.label[2]}
         </label>
         <input
            id="requestInput"
            className="rounded-md mb-5 p-3 border-2"
            type="text"
            name="request"
            required
         />
         <label htmlFor="subjectInput" className="font-medium">
           {content.contact.label[3]}
         </label>
         <input
            id="subjectInput"
            className="rounded-md mb-5 p-3 border-2"
            type="text"
            name="subject"
            required
         />
         <label htmlFor="messageInput" className="font-medium">
           {content.contact.textareaLabel}
         </label>
         <textarea
            id="messageInput"
            style={{maxHeight: "12rem", minHeight: "12rem"}}
            className="rounded-md mb-5 p-3 h-48 border-2"
            name="message"
            cols="30"
            rows="10"
            required
         ></textarea>
         <div className="mt-3 mb-5">
           <input
              id="checkboxInput"
              type="checkbox"
              name="radio"
              required
              className="mr-3"
           />
           <label htmlFor="checkboxInput" className="text-sm">
             {content.contact.radioText}
           </label>
         </div>
         <button
            aria-label="Submit form"
            type="submit"
            className="mb-2 bg-gray-900 px-10 py-3 text-lg uppercase text-white rounded-3xl outline-none focus:outline-none
            transition-all duration-300 ease-out hover:bg-white hover:text-gray-900 border-2 border-gray-900"
         >
           {content.contact.butText}
         </button>
       </form>
     </>
  );
};

export default ContactForm;
