import React from "react";
import PubCarousel from "./PubCarousel";

const Sync = ({ content }) => {
  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };


  return (
    <div id="sync" className="w-full text-black bg-white">
      <div className="py-24 text-right">
        <div className="p-12 xl:px-64 ">
          <div className="flex flex-col md:w-8/12 md:ml-auto md:mr-0 xl:w-5/12">
            <h3 className="text-3xl font-medium mb-5 md:text-5xl">
              {content.sync.title}
            </h3>
            <hr className="mb-5 w-24 ml-auto mr-0" />
            <p className="my-12">{content.sync.text}</p>
            <a
              className="w-1/2 ml-auto mr-0 xl:transition-all xl:duration-300 xl:ease-in-out
                px-2 text-center py-2 border-2 border-gray-700 rounded-xl bg-gray-700 hover:opacity-75"
              href={content.sync.buttons.url}
              target="_blank"
              rel="noreferrer"
            >
              <button onClick={() => openInNewTab("https://composers-library.kaaproduction.com/")}>
                <p className="text-white text-5xl w-full items-center flex flex-col justify-center">
                  {content.sync.button_title}
                </p>
              </button>
            </a>
          </div>
        </div>
        <div className="mt-10 w-full hidden lg:block">
          <PubCarousel csp={50} itemNb={5} content={content} />
        </div>
        <div className="mt-10 w-full lg:hidden">
          <PubCarousel csp={100} itemNb={5} content={content} />
        </div>
        <div className="mt-5 p-12 xl:px-64 text-left">
          <hr className="mb-5" />
          <h3 className="text-3xl font-medium mb-5 md:text-5xl">
            {content.sync.subTitle}
          </h3>
          <hr className="mb-5 w-24" />
          <div className="flex flex-col lg:flex-row lg:flex-wrap">
            {content.sync.carousel.map((item, index) => {
              if (index !== 3)
                return (
                  <a
                    key={index}
                    href={item.url}
                    target="_blank"
                    rel="noreferrer"
                    className="lg:w-5/12 m-auto mb-10"
                  >
                    <div className="relative m-auto transition-all duration-200 ease-in-out xl:hover:opacity-75">
                      <img
                        className="object-contain shadow-xl"
                        src={item.img}
                        alt={item.name}
                      />
                      <p className="text-white absolute bottom-0 z-10 text-left left-0 ml-10 mb-10 text-3xl font-medium">
                        {item.name}
                      </p>
                      <p className="text-white absolute bottom-0 z-10 text-left left-0 ml-10 mb-4 text-sm font-light">
                        Listen Now
                      </p>
                      <div
                        style={{
                          backgroundImage:
                            "linear-gradient(180deg, transparent, rgba(37, 37, 37, 0.50), #141414)",
                        }}
                        className="absolute bottom-0 w-full h-32"
                      ></div>
                    </div>
                  </a>
                );
              else
                return (
                  <div key={index} className="lg:w-5/12 m-auto mb-10">
                    <div className="relative m-auto transition-all duration-200 ease-in-out xl:hover:opacity-75">
                      <img
                        className="object-contain shadow-xl"
                        src={item.img}
                        alt={item.name}
                      />
                      <p className="text-white absolute bottom-0 z-10 text-left left-0 ml-10 mb-10 text-3xl font-medium">
                        {item.name}
                      </p>
                      <div
                        style={{
                          backgroundImage:
                            "linear-gradient(180deg, transparent, rgba(37, 37, 37, 0.50), #141414)",
                        }}
                        className="absolute bottom-0 w-full h-32"
                      ></div>
                    </div>
                  </div>
                );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sync;
