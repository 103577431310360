import React from "react";
import { Link as ScrollLink } from "react-scroll";

const Banner = ({ content }) => {
  return (
    <div
      id="header"
      style={{
        background: `url(${content.header.bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
      className="min-h-screen h-screen max-h-screen flex relative overflow-hidden"
    >
      <img
        className="m-auto pb-20"
        src={content.header.logo}
        alt="logo Sidekick"
      />
      <div
        style={{ transform: "rotate(180deg)", zIndex: "1" }}
        className="absolute bottom-0 left-0 -mb-1 w-full"
      >
        <svg viewBox="0 0 500 100">
          <path
            d="M1200 120L0 16.48 0 0 1200 0 1200 120z"
            style={{ fill: "#fff" }}
          ></path>
        </svg>
        <ScrollLink
          style={{
            zIndex: "2",
            marginLeft: "50%",
          }}
          className="hidden lg:block absolute top-0 left-0 mt-12 outline-none cursor-pointer focus:outline-none animate-bounce 
            transition-all duration-300 ease-in-out xl:hover:text-gray-800"
          to="banner"
          spy={true}
          smooth={true}
          offset={0}
          duration={900}
        >
          <i
            style={{
              transform: "rotate(180deg)",
            }}
            className="fas fa-chevron-down text-3xl"
          ></i>
        </ScrollLink>
      </div>
    </div>
  );
};

export default Banner;
