import React from "react";
import { Carousel } from "react-responsive-carousel";

import "react-responsive-carousel/lib/styles/carousel.min.css";

const ArtistCarousel = ({ content, csp, itemNb, nb }) => {
  const car =
    nb === 0 ? content.production.carousel : content.publishing.carousel;
  return (
    <Carousel
      autoPlay={true}
      infiniteLoop={true}
      interval={3000}
      showStatus={false}
      showIndicators={false}
      showThumbs={false}
      centerMode={true}
      centerSlidePercentage={csp}
      selectedItem={itemNb}
      renderArrowPrev={(onClickHandler, hasPrev) =>
        hasPrev && (
          <button
            aria-label="Precedent"
            type="button"
            onClick={onClickHandler}
            className="absolute z-10 bottom-0 right-0 mb-1 mr-16 md:mr-20 lg:mr-32 xl:mr-40 outline-none focus:outline-none rounded-full text-2xl
              transform transition duration-300 hover:scale-110"
          >
            <i className="fas fa-chevron-left"></i>
          </button>
        )
      }
      renderArrowNext={(onClickHandler, hasNext) =>
        hasNext && (
          <button
            aria-label="Next"
            type="button"
            onClick={onClickHandler}
            className="float-right z-10 mb-1 mr-10 md:mr-12 lg:mr-24 xl:mr-32 outline-none focus:outline-none rounded-full text-2xl
              transform transition duration-300 hover:scale-110"
          >
            <i className="fas fa-chevron-right"></i>
          </button>
        )
      }
    >
      {car.map((item, index) => {
        return (
          <a key={index} href={item.url} target="_blank" rel="noreferrer">
            <div className="relative mb-8 mr-10 transition-all duration-200 ease-in-out xl:hover:opacity-75">
              <img
                style={{ maxWidth: "538px", minWidth: "253px" }}
                className="object-contain"
                src={item.img}
                alt={item.name}
              />
              <p className="text-white absolute bottom-0 z-10 text-left left-0 ml-10 mb-10 text-3xl font-medium">
                {item.name}
              </p>
              <p className="text-white absolute bottom-0 z-10 text-left left-0 ml-10 mb-4 text-sm font-light">
                Listen Now
              </p>
              <div
                style={{
                  minWidth: "253px",
                  backgroundImage:
                    "linear-gradient(180deg, transparent, rgba(37, 37, 37, 0.50), #141414)",
                }}
                className="absolute bottom-0 w-full h-32"
              ></div>
            </div>
          </a>
        );
      })}
    </Carousel>
  );
};

export default ArtistCarousel;
