import React, { useState, useCallback, useEffect } from "react";
import { Link as ScrollLink } from "react-scroll";
import { HamburgerCollapse } from "react-animated-burgers";
import ScrollLock from "react-scrolllock";
import {useLocation, Link} from "react-router-dom";

  const Navbar = ({ content, setLanguage }) => {
  const location = useLocation();

  const [isActive, setIsActive] = useState(false);
  const [show, handleShow] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 900) handleShow(true);
      else handleShow(false);
    });
    return () => {
      window.removeEventListener("scroll", null);
    };
  }, [handleShow]);

  const toggleButton = useCallback(
    () => setIsActive((prevState) => !prevState),
    []
  );

    const openInNewTab = url => {
      window.open(url, '_blank', 'noopener,noreferrer');
    };

  return (
    <nav
      style={{
        backgroundColor: "#F6F6F6",
        opacity: !show && location.pathname === "/" && "0",
      }}
      className="z-50 fixed top-0 w-full h-16 transition-opacity duration-500 ease-in-out"
    >
      <ScrollLock isActive={isActive} />
      <div className="flex w-full h-full">
        <div className="my-auto flex-auto ml-5">
          <ScrollLink
            className="outline-none focus:outline-none mx-auto"
            to="header"
            spy={true}
            smooth={true}
            offset={0}
            duration={900}
          >
            <img
              className="w-10 cursor-pointer"
              src={content.nav.logo}
              alt="profile"
            />
          </ScrollLink>
        </div>
        <div className="my-auto mr-3 lg:hidden">
          <HamburgerCollapse
            className="focus:outline-none"
            barColor="#000000"
            buttonWidth={20}
            {...{ isActive, toggleButton }}
          />
        </div>
        <div className="my-auto mr-3  lg:flex lg:flex-auto">
          {location.pathname !== "/terms_of_service" && (
            <div className="mr-10">
              {content.nav.links.map((link, index) => {
                return (
                    (link.text === "Library" ?
                        (
                              <button className="mr-10 font-semibold cursor-pointer animHover relative inline-block"
                              key={index}
                              onClick={() => openInNewTab("https://composers-library.kaaproduction.com/")}>
                              {link.text}
                              </button>
                        )
                        :
                        (
                            <ScrollLink key={index} to={link.to} smooth={true}>
                              <span
                                  className="mr-10 font-semibold cursor-pointer animHover relative inline-block"
                                  key={index}
                              >
                              {link.text}
                              </span>
                            </ScrollLink>
                        )
                    )
                );
              })}
            </div>
          )}
          {location.pathname === "/terms_of_service" && (
            <div className="mr-10">
              <Link to="/">
                <span className="mr-10 font-semibold cursor-pointer animHover relative inline-block">
                  {content.terms.nav}
                </span>
              </Link>
            </div>
          )}
          <div>
            {content.nav.social.map((item, index) => {
              return (
                <a
                  key={index}
                  aria-label="Social links"
                  href={item.to}
                  rel="noreferrer"
                  target="_blank"
                >
                  <i
                    className={`${item.icon} animHover relative inline-block ${
                      index % 2 !== 0 && "mx-5"
                    }`}
                  ></i>
                </a>
              );
            })}
          </div>
          <div className="flex m-auto">
            <button
              aria-label="change language"
              className="outline-none focus:outline-none font-semibold animHover relative inline-block"
              onClick={() => setLanguage(content.nav.langue[0])}
            >
              {content.nav.langue[0]}
            </button>
            <p className="mx-3">|</p>
            <button
              aria-label="change language"
              className="outline-none focus:outline-none font-semibold animHover relative inline-block"
              onClick={() => setLanguage(content.nav.langue[1])}
            >
              {content.nav.langue[1]}
            </button>
          </div>
        </div>
      </div>
      <div
        style={
          isActive
            ? {
                visibility: "visible",
                opacity: "1",
                transition: "opacity .7s linear",
              }
            : {
                visibility: "hidden",
                opacity: "0",
                transition: "visibility 0s .7s, opacity .7s linear",
              }
        }
        className={`bg-white h-screen`}
      >
        <div className="w-full h-screenDivised flex">
          <div className="w-full flex flex-col m-auto">
            {location.pathname !== "/terms_of_service" &&
              content.nav.links.map((link, index) => {
                return (
                  <ScrollLink
                    onClick={toggleButton}
                    className="m-auto pb-5 cursor-pointer"
                    key={index}
                    to={link.to}
                    smooth={true}
                    offset={-60}
                    duration={900}
                  >
                    <span className="text-xl font-bold" key={index}>
                      {link.text}
                    </span>
                  </ScrollLink>
                );
              })}
            {location.pathname === "/terms_of_service" && (
              <div className="m-auto">
                <Link to="/">
                  <span className="m-auto text-xl font-bold cursor-pointer animHover relative inline-block">
                    {content.terms.nav}
                  </span>
                </Link>
              </div>
            )}
          </div>
        </div>
        <div
          style={{ background: "#22222B" }}
          className="w-full h-screenDivised flex"
        >
          <div className="w-full flex flex-col m-auto text-white">
            <div className="flex m-auto text-2xl">
              <button
                aria-label="change language"
                className="outline-none focus:outline-none"
                onClick={() => {
                  setLanguage(content.nav.langue[0]);
                  toggleButton();
                }}
              >
                {content.nav.langue[0]}
              </button>
              <p className="mx-3">|</p>
              <button
                aria-label="change language"
                className="outline-none focus:outline-none"
                onClick={() => {
                  setLanguage(content.nav.langue[1]);
                  toggleButton();
                }}
              >
                {content.nav.langue[1]}
              </button>
            </div>
            <div className="flex flex-col m-auto text-sm text-center mt-5">
              <p className="text-gray-500">{content.nav.copyright}</p>
              <p>{content.nav.privacy}</p>
            </div>
            <div className="flex m-auto text-2xl text-center mt-5">
              {content.nav.social.map((item, index) => {
                return (
                  <a
                    aria-label="Social links"
                    key={index}
                    href={item.to}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <i
                      className={`${item.icon} ${index % 2 !== 0 && "mx-5"}`}
                    ></i>
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
