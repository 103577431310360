import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

const Map = () => {
  const position = [43.6115526, 1.4244068000000425];

  return (
    <MapContainer
      className="h-64 md:h-mapTablette"
      center={position}
      zoom={16}
      scrollWheelZoom={true}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={position}>
        <Popup>
          Toulouse <br /> France
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default Map;
