import React from "react";
import { Carousel } from "react-responsive-carousel";

import "react-responsive-carousel/lib/styles/carousel.min.css";

import ArtistCarousel from "./ArtistCarousel";

const Production = ({ content }) => {
  return (
    <div
      id="production"
      style={{
        backgroundImage: `url(${content.production.backgroundImage})`,
        backgroundRepeat: "repeat",
        backgroundSize: "200px",
      }}
      className="w-full text-white md:pt-2"
    >
      <div className="p-12 py-24 text-right xl:px-64">
        <h1 className="text-5xl font-medium mb-5">
          {content.production.title}
        </h1>
        <hr className="w-24 float-right" />
        <div className="flex flex-col md:flex-row w-full">
          <div className="mt-12 md:w-6/12 lg:p-5 mr-10 flex">
            <Carousel
              className="w-full"
              autoPlay={true}
              infiniteLoop={true}
              interval={2500}
              showArrows={false}
              showStatus={false}
              showIndicators={false}
              showThumbs={false}
            >
              {content.production.prodVisuel.map((item, index) => {
                return (
                  <img
                    style={{
                      maxWidth: "350px",
                    }}
                    className="m-auto overflow-hidden"
                    key={index}
                    src={item}
                    alt="prodVisuel"
                  />
                );
              })}
            </Carousel>
          </div>
          <div className="mt-12 md:w-6/12 lg:p-5">
            {content.production.text.map((item, index) => {
              return (
                <p key={index} className="mt-8 text-justify md:text-lg">
                  {item}
                </p>
              );
            })}
          </div>
        </div>
        <div className="md:text-left">
          <h2 className="text-3xl font-medium mt-10 mb-5">
            {content.production.subTitle}
          </h2>
          <p className="my-10 md:w-6/12">{content.production.subText}</p>
        </div>
        <div className="md:flex">
          <div className="md:w-5/12 md:m-auto">
            <div>
              <p>
                <iframe
                  title="SidekickPlaylist"
                  src="https://open.spotify.com/embed/playlist/2IQSyzfYZvR6ZByHGjMvpv"
                  width="100%"
                  height="380"
                  frameBorder="0"
                  allowtransparency="true"
                  allow="encrypted-media"
                ></iframe>
              </p>
            </div>
          </div>
          <div className="mt-10 md:mt-0 md:w-5/12 md:m-auto">
            <h2 className="text-3xl font-medium mb-5">
              {content.production.subSubTitle}
            </h2>
            <hr className="w-24 float-right" />
            <p className="mt-10">{content.production.subSubText}</p>
            <div className="text-black mt-10 text-right p-5">
              <img
                className="w-6/12 my-auto ml-auto mr-0 mb-10"
                src={content.production.logo.icon}
                alt="icons"
              />
              <a
                href="https://sidekick-music.com/"
                target="_blank"
                rel="noreferrer"
                className="bg-gray-700 text-white rounded-2xl px-6 py-4 border-2 border-gray-700 font-semibold transition-all duration-300 ease-out hover:bg-white hover:text-gray-700"
              >
                {content.production.logo.text}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="pb-20">
        <div className="mt-10 w-full hidden lg:block">
          <ArtistCarousel csp={30} itemNb={0} content={content} nb={0} />
        </div>
        <div className="mt-10 w-full lg:hidden">
          <ArtistCarousel csp={73} itemNb={0} content={content} nb={0} />
        </div>
      </div>
    </div>
  );
};

export default Production;
