import React from "react";
import { Link } from "react-router-dom";

const Footer = ({ content, setLanguage }) => {
  return (
    <div
      style={{ background: "#22222b" }}
      className="w-full text-black bg-gray-300"
    >
      <div className="w-full flex flex-col m-auto text-white p-10">
        <div className="flex m-auto text-2xl">
          <button
            aria-label="change language"
            className="outline-none focus:outline-none animHoverFooter relative inline-block"
            onClick={() => setLanguage(content.nav.langue[0])}
          >
            {content.nav.langue[0]}
          </button>
          <p className="mx-3">|</p>
          <button
            aria-label="change language"
            className="outline-none focus:outline-none animHoverFooter relative inline-block"
            onClick={() => setLanguage(content.nav.langue[1])}
          >
            {content.nav.langue[1]}
          </button>
        </div>
        <Link
          to="terms_of_service"
          className="cursor-pointer flex flex-col m-auto text-sm text-center mt-5"
        >
          <p className="text-gray-500">{content.nav.copyright}</p>
          <p>{content.nav.privacy}</p>
        </Link>
        <div className="flex m-auto text-2xl text-center mt-5">
          {content.nav.social.map((item, index) => {
            return (
              <a key={index} href={item.to} rel="noreferrer" target="_blank">
                <i
                  className={`${
                    item.icon
                  } animHoverFooter relative inline-block ${
                    index % 2 !== 0 && "mx-5"
                  }`}
                ></i>
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Footer;
