import React from "react";
import { Link as ScrollLink } from "react-scroll";

const Header = ({ content }) => {
  return (
    <div
      id="banner"
      className="w-full text-black bg-white min-h-screen relative pb-16"
    >
      <div className="absolute top-0 left-0 z-0 w-full h-full"></div>
      <div className="relative z-10 p-12 pt-24 md:flex md:flex-wrap md:pt-40 xl:px-64">
        <div className="md:w-5/12 md:m-auto">
          <h1 className="text-5xl font-medium mb-5">{content.header.title}</h1>
          <hr className="mb-10 w-24" />
          <p className="mb-5 text-justify">{content.header.text}</p>
          <p className="mb-5 text-justify">{content.header.subtext}</p>
        </div>
        <div className="md:w-5/12 md:m-auto">
          <img
            className="xl:w-full xl:m-auto xl:-mt-20"
            src={content.header.img}
            alt="studio"
          />
        </div>
        {content.header.blocs.map((item, index) => {
          return (
            <div
              key={index}
              className="md:w-5/12 m-auto mt-16 lg:mt-32 xl:mt-8 xl:w-3/12"
            >
              <img className="w-16 mb-3" src={item.icon} alt="icons" />
              <ScrollLink
                className="outline-none cursor-pointer focus:outline-none animHovered relative inline-block"
                to={item.to}
                spy={true}
                smooth={true}
                offset={-80}
                duration={900}
              >
                <h2 className="text-4xl font-medium mb-3">{item.title}</h2>
              </ScrollLink>
              <p className="text-sm text-justify text-gray-700">{item.text}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Header;
