import React from "react";

import Form from "./ContactForm";
import Map from "./Map";

const Contact = ({ content }) => {
  return (
    <div id="contact" className="w-full text-black bg-white">
      <div className="p-12 py-24 md:flex xl:px-64">
        <div className="md:w-5/12 md:m-auto">
          <h1 className="text-5xl font-medium">{content.contact.title}</h1>
          <hr className="my-5 w-24" />
          <h2 className="mb-10">{content.contact.text}</h2>
          <div>
            {content.contact.cat.map((item, index) => {
              return (
                <div className="mt-5" key={index}>
                  <img className="w-12 my-2" src={item.icon} alt="icons" />
                  <p className="font-medium text-lg">{item.text}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="mt-10 md:w-5/12 md:m-auto">
          <Form content={content} />
        </div>
      </div>
      <div className="w-full">
        <Map />
      </div>
    </div>
  );
};

export default Contact;
