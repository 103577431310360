const contentEN = {
  contactFormSuccess: {
    title: "Thanks !",
    message: "Your message has been sent!",
  },
  contactFormError: {
      title: "Error !",
      message: "An error occured, please try again later.",
  },
  nav: {
    logo: process.env.PUBLIC_URL + "/assets/logoKaa.png",
    links: [
      { text: "Production", to: "production" },
      { text: "Publishing", to: "publishing" },
      { text: "Sync", to: "sync" },
      { text: "Library", to: "library" },
      { text: "Studio", to: "studio" },
      { text: "Contact us", to: "contact" },
    ],
    langue: ["FR", "EN"],
    copyright: "Copyright © 2021 All Rights Reserved.",
    privacy: "Privacy Policy",
    social: [
      {
        icon: "fab fa-facebook-square",
        to: "https://www.facebook.com/KaaProduction/",
      },
      {
        icon: "fab fa-linkedin",
        to: "https://www.linkedin.com/company/kaa-production/?originalSubdomain=fr",
      },
      {
        icon: "fab fa-youtube",
        to: "https://www.youtube.com/channel/UCXSh1CqFOTM-7-1PRvPjWGQ",
      },
    ],
  },
  header: {
    logo: process.env.PUBLIC_URL + "/assets/logooo.png",
    bg: process.env.PUBLIC_URL + "/assets/exterieur.jpg",
    title: "Who we are",
    text: "Kaa Production stands for expertise in all Technical, Financial, A&R solutions that are bound to music.",
    subtext:
      "As we’re constantly on the lookout for new technologies and partners, we’re keen to adopt disruptive business models and on a mission to offer the most relevant and exciting solutions to our clients, partners and audience.",
    img: process.env.PUBLIC_URL + "/assets/who-we-are.png",
    blocs: [
      {
        to: "publishing",
        icon: process.env.PUBLIC_URL + "/assets/note.svg",
        title: "Publishing",
        text: "Working with Songwriters and Rightholders of all genres, we’re walking you through every steps it take to make your creations rewarding, in complete transparency. Registered as a Publishing Company since 2012 , we’ve embraced every steps, responsibilities, tasks and assignments it takes to a Publisher’s Job.",
      },
      {
        to: "production",
        icon: process.env.PUBLIC_URL + "/assets/disk.svg",
        title: "Production",
        text: "More than every other area of the Music Industry, production does not make a single day like another. It’s following this phrase that we’re keeping up with the fast pace at wich our job is changing. Learning, creating and building new strategies for our allies and ourselves.",
      },
      {
        to: "studio",
        icon: process.env.PUBLIC_URL + "/assets/panel.svg",
        title: "Studio",
        text: "We’re offering a wide range of services to help you to be as creative and productive as possible while in our complete and coherent facilities.",
      },
    ],
  },
  production: {
    backgroundImage: process.env.PUBLIC_URL + "/assets/noise.gif",
    title: "Production",
    text: [
      "Born as a Record Label in the late 2000’s, we recorded and distributed music in all forms and shapes.",
      "More than every other area of the Music Industry, production does not make a single day like another. It’s following this phrase that we’re keeping up with the fast pace at wich our job is changing. Learning, creating and building new strategies for our allies and ourselves.",
      "Knowledge, resources and services we provide include:",
      "A&R, Talent Management, Financing, Consulting, Content creation, Content Management, Design… and many more.",
    ],
    prodVisuel: [
      process.env.PUBLIC_URL + "/assets/prodVisuel/post-spSquareUpdatedIG.jpg",
      process.env.PUBLIC_URL + "/assets/prodVisuel/complex-MM.jpg",
      process.env.PUBLIC_URL + "/assets/prodVisuel/spotifyp-thebaddest-maj.jpg",
      process.env.PUBLIC_URL + "/assets/prodVisuel/fns-thebaddest.png",
      process.env.PUBLIC_URL + "/assets/prodVisuel/thoughts-sp.jpg",
      process.env.PUBLIC_URL + "/assets/prodVisuel/sp-mmh.jpg",
      process.env.PUBLIC_URL + "/assets/prodVisuel/tsis-mmh.jpg",
      process.env.PUBLIC_URL + "/assets/prodVisuel/guettapenxL&R-1.jpg",
      process.env.PUBLIC_URL + "/assets/prodVisuel/DJMAG-Prem1.jpg",
      process.env.PUBLIC_URL + "/assets/prodVisuel/mirageclub-bbc6.png",
      process.env.PUBLIC_URL + "/assets/prodVisuel/cl-rs.jpg",
    ],
    subTitle: "",
    subText:
      "Driven by passion, our team designs new ways of consuming and promoting Music & Artists throughout our entity:",
    subSubTitle: "Sidekick Music",
    subSubText: [
      "Sidekick Music is a full on Electronic Music dedicated Record Label with a penchant for Indie & Nu Disco. ",
      "Literally defined as an “Informal close friend who accompanies another on adventures”, our mission is to craft a pedestal for artists and their music to stand out on.",
    ],
    logo: {
      icon: process.env.PUBLIC_URL + "/assets/sidekickMusicWhite.png",
      text: "Website",
    },
    carousel: [
      {
        url: "https://open.spotify.com/artist/3UlxLrm4Nt8Ga2eLv4RoN2?si=aa-nLRmMRuqM2VUj1lNy2A",
        name: "Axmod",
        img: process.env.PUBLIC_URL + "/assets/artists/axmod-kaa.jpg",
      },
      {
        url: "https://open.spotify.com/artist/3eUpxakSdjFZ5ROQKgfL2W?si=r5muMV07QsiEXPOicAwtdg",
        name: "Tentendo",
        img: process.env.PUBLIC_URL + "/assets/artists/tentendo-kaa.jpg",
      },
      {
        url: "https://open.spotify.com/artist/2v3W6NfNDwGtyPyBnekR0Y?si=DueI8KdoRgG3F2-FfPeuHALura",
        name: "Vilda",
        img: process.env.PUBLIC_URL + "/assets/artists/vilda-kaa.jpg",
      },
      {
        url: "https://open.spotify.com/artist/1hcGLw65s3bF6qC2gBri1m?si=EDjdZtE6Qaq63ZtfCrFfDw",
        name: "Rozie",
        img: process.env.PUBLIC_URL + "/assets/artists/rozie-kaa.jpg",
      },
      {
        url: "https://open.spotify.com/artist/6wa2PiIWrIhhz6lRQEGQpO?si=cX0AamsCQPuD9uhdXHmRxA",
        name: "Pastel",
        img: process.env.PUBLIC_URL + "/assets/artists/pastel-kaa.jpg",
      },
      {
        url: "https://open.spotify.com/artist/4MismZLKqMb2Qb2HjK4sdE?si=UiXAjeL8R2q_I5EEXHID1w",
        name: "Napkey",
        img: process.env.PUBLIC_URL + "/assets/artists/napkey-kaa.jpg",
      },
      {
        url: " https://open.spotify.com/artist/5KtUig38eqxK2rOtHZnz0k?si=t3P2tPnISvG8p1xWe4uA3Q",
        name: "Jafunk",
        img: process.env.PUBLIC_URL + "/assets/artists/jafunk-kaa.jpg",
      },
      {
        url: "https://open.spotify.com/artist/0iFmnAL0wjQU7r07tV4J09?si=H-qyQ5FsSf-u2F07yVa4ow",
        name: "Holow",
        img: process.env.PUBLIC_URL + "/assets/artists/holow-kaa.jpg",
      },
      {
        url: "https://open.spotify.com/artist/2V5pNnEusCUalcRoeG9uOL?si=NlR6Sr-oTW-STbrxeRAzcQ",
        name: "Berry Juice",
        img: process.env.PUBLIC_URL + "/assets/artists/berry-juice-kaa.jpg",
      },
      {
        url: "https://open.spotify.com/artist/0laK5v55I88OKRru4xOxwD?si=mQoiw2z-Tvm9WeDD7wjJiA",
        name: "Gino Holroyd",
        img: process.env.PUBLIC_URL + "/assets/artists/gino-holroyd-kaa.jpg",
      },
      {
        url: "https://open.spotify.com/artist/6sN9vsKttRRd1c4sFca1Ws?si=33F53LWpTzqGt_jmNZlCvA",
        name: "Lura & Ruggerio",
        img: process.env.PUBLIC_URL + "/assets/artists/lura-&-ruggiero-kaa.jpg",
      },
      {
        url: "https://open.spotify.com/artist/04Bh2L9di4Jh0SBYDSdYrz?si=vx1B-DH2TWOARrQj5yB37Q",
        name: "Meyze",
        img: process.env.PUBLIC_URL + "/assets/artists/meyze-kaa.jpg",
      },
      {
        url: "https://open.spotify.com/artist/1lCoglJluTQoUO2vbKfyjh?si=BboKYnu_TpOkrB3Ovv2u4w",
        name: "Mirage Club",
        img: process.env.PUBLIC_URL + "/assets/artists/mirage-club-kaa.jpg",
      },
      {
        url: "https://open.spotify.com/artist/4prjwjwLedf54KWAs54ErF?si=FYa8YvaBREa113cBAywGBw",
        name: "Thomas Ford",
        img: process.env.PUBLIC_URL + "/assets/artists/thomas-ford-kaa.jpg",
      },
    ],
  },
  publishing: {
    title: "Publishing",
    subTitle: "Our Expertise",
    text: [
      "Independent scale for major steps has been our motto from day one.",
      "Working with Songwriters and Rightholders of all genres, we’re walking you through every steps it take to make your creations rewarding, in complete transparency.",
    ],
    subSubTitle: "Music Publisher",
    subSubtext:
      "Skilled as Music publisher and highly involved in Collection Societies at a political level, we’ve helped pushing boundaries in France to method regulations around new music usages.",
    cat: [
      {
        icon: process.env.PUBLIC_URL + "/assets/document.svg",
        text: "Registration",
      },
      {
        icon: process.env.PUBLIC_URL + "/assets/increase.svg",
        text: "Revenue Increase",
      },
      {
        icon: process.env.PUBLIC_URL + "/assets/screen.svg",
        text: "Licensing",
      },
      {
        icon: process.env.PUBLIC_URL + "/assets/radio.svg",
        text: "Sub Publishing",
      },
    ],
    img: process.env.PUBLIC_URL + "/assets/contrat.jpg",
    carousel: [
      {
        url: "https://open.spotify.com/artist/1YuzFn3UbXmdSqkmQu9VFg?si=-kMFkaMJR5OnKEdvCzcBTA",
        name: "Zitoune",
        img: process.env.PUBLIC_URL + "/assets/artists/zitoune-kaa.jpg",
      },
      {
        url: "https://open.spotify.com/artist/6Le8YP9kxvtYRKGZNDR4Ip?si=6k7_G9q2Tc2cnb8dqkqzLA",
        name: "Dorian Craft",
        img: process.env.PUBLIC_URL + "/assets/artists/dorianCraft-kaa.jpg"
      },
      {
        url: "https://open.spotify.com/artist/5TkDGv15Vfe7X91xleonsi?si=qGwt4JZzToC7SMkBoyzjew",
        name: "LMA",
        img: process.env.PUBLIC_URL + "/assets/artists/lma-kaa.jpg",
      },
      {
        url: "https://open.spotify.com/artist/2ZUmKzZUZPBuvBrdTM6Rw1?si=AoHl78gvSjyAz1mT-6ju7Q",
        name: "Amélie Martinez",
        img: process.env.PUBLIC_URL + "/assets/artists/amelieMartinez-kaa.jpg",
      },
      {
        url: "https://open.spotify.com/artist/0AmoPop9C4unfUJtBT005d?si=2TYgDq5hRIWSeQholdRxbQ",
        name: "Selenite",
        img: process.env.PUBLIC_URL + "/assets/artists/selenite-kaa.jpg",
      },
      {
        url: "https://open.spotify.com/artist/0sn0w4FAiA4Tll9OpV1oon?si=xWG8jsklQzK4VFPtufCRKQ",
        name: "DJ Getdown",
        img: process.env.PUBLIC_URL + "/assets/artists/DJ-getdown-kaa.jpg",
      },
      {
        url: "https://open.spotify.com/artist/5t5UKNdX3RZUCePoXSYx4N?si=D3cZkW2wSa240zsVHUstXA",
        name: "Willo",
        img: process.env.PUBLIC_URL + "/assets/artists/willo-kaa.jpg",
      },
      {
        url: "https://open.spotify.com/artist/7si9pFZZlPdZPeOclH9vgm?si=uCU7e-UcRhqEZr1zyfJdqQ",
        name: "Amine Edge & DANCE",
        img: process.env.PUBLIC_URL + "/assets/artists/amineedge&dance-kaa.jpg",
      },
      {
        url: "https://open.spotify.com/artist/5mRKOTs9MKx1xz4Vh5jFLT?si=0dQbF_SXTQWxStY00S2sZQ",
        name: "Paranoid",
        img: process.env.PUBLIC_URL + "/assets/artists/paranoid-kaa.jpg",
      },
      {
        url: "https://open.spotify.com/artist/3iqVIQJX1U9EaX1G5BDGKU?si=_hSSwmksRa-dfvuoMHrc5Q",
        name: "Oz",
        img: process.env.PUBLIC_URL + "/assets/artists/oz-kaa.jpg",
      },
      {
        url: "https://open.spotify.com/artist/1dsilrkrvxXE6I02SWfDYD?si=AqMizp9LTo263Wp1tTCtcA",
        name: "Kosling",
        img: process.env.PUBLIC_URL + "/assets/artists/kosling-kaa.jpg",
      },
      {
        url: "https://open.spotify.com/artist/6uPDwlTytGAzI42bG23K5I?si=K2MJg6DnSBGmFPCBemi3sg",
        name: "Gaba",
        img: process.env.PUBLIC_URL + "/assets/artists/gaba-kaa.jpg",
      },
      {
        url: "https://open.spotify.com/artist/7Cw2ntWyzAsY9RUqD0Qp5N?si=-ONZrCApQFKba9pkJa0QSQ",
        name: "Kijote",
        img: process.env.PUBLIC_URL + "/assets/artists/kijote-kaa.jpg",
      },
      {
        url: "https://open.spotify.com/artist/6YftCH7x8Qs74RIyHZIYrm?si=_cTw4r-7RKWSEUQAfyXfUw",
        name: "Keemah",
        img: process.env.PUBLIC_URL + "/assets/artists/keemah-kaa-V1.jpg",
      },
      {
        url: "https://open.spotify.com/artist/1s0eio5dIwwC2sIkOm0HY6?si=05EkBx_XRxGUxs2Qj2QZ1A",
        name: "Franz Robert Wild",
        img: process.env.PUBLIC_URL + "/assets/artists/franz-robert-wild.jpg",
      },
      {
        url: "https://open.spotify.com/artist/19kmLR4tlgvVPQGNTjwFMS?si=2fXU-oxMSpWbyRb3hvg63g",
        name: "Bertrand Betsch",
        img: process.env.PUBLIC_URL + "/assets/artists/bertrand-betsch-kaa.jpg",
      },
      {
        url: "https://open.spotify.com/artist/39TK4RM8bEzWa1BoBxsSko?si=i7XgaNQyTMab8Ek3g197zQ",
        name: "Juanito",
        img: process.env.PUBLIC_URL + "/assets/artists/juanito-kaa.jpg",
      },
      {
        url: "",
        name: "Maxime Prebet",
        img: process.env.PUBLIC_URL + "/assets/artists/maxime-prebet-kaa.jpg",
      },
      {
        url: "https://open.spotify.com/artist/1rPvh2OggGcSpB8iBaTksJ?si=0scMERy6RImrm1xPD33FGQ",
        name: "Izadorä",
        img: process.env.PUBLIC_URL + "/assets/artists/izadora-kaa.jpg",
      },
      {
        url: "https://open.spotify.com/artist/49Ypkl4ltiWhxXcLILO8W1?si=dudkFkZuRF2C2FmN7DA4MQ",
        name: "Blackbird Hill",
        img: process.env.PUBLIC_URL + "/assets/artists/blackbird-hill-kaa.jpg",
      },
      {
        url: "https://open.spotify.com/artist/65EEOCJLWD3H3z4BLJLLG9?si=oyhWax9-Td6F1nB7LOLpuA",
        name: "Bilbao Kung Fu",
        img: process.env.PUBLIC_URL + "/assets/artists/bilbao-kung-fu-kaa.jpg",
      },{
        url: "https://open.spotify.com/artist/78HdrxOP9l5R26N82dzEeD?si=PdMiL2AhQzuBVgf24DhVfg",
        name: "Herjay",
        img: process.env.PUBLIC_URL + "/assets/artists/herjay-kaa.jpg",
      },{
        url: "https://open.spotify.com/artist/4Yr14Z43qWALBoZ2qJBmg8?si=E2PARQpnSqeGQOyoPHbTRw",
        name: "AWKD",
        img: process.env.PUBLIC_URL + "/assets/artists/AWKD-kaa.jpg",
      },{
        url: "https://open.spotify.com/artist/2P9wN49BSfVWhDo5x1IION?si=SNxTluDPSlyoS_QIyVXfzQ",
        name: "LRMS",
        img: process.env.PUBLIC_URL + "/assets/artists/LRMS-kaa.jpg",
      },
    ],
    canvaFirstLink: "https://www.canva.com/design/DAEdbsCOXRs/view?embed",
    canvaSecondLink:
      "https://www.canva.com/design/DAEbKOt71mc/view?utm_content=DAEbKOt71mc&amp;utm_campaign=designshare&amp;utm_medium=embeds&amp;utm_source=link",
    canvaText:
      "From Sidekick to Publishing, check out our artists’ latest news, releases & much more... It's all there.",
  },
  subpublishing: {
    title: "Sub Publishing",
    subTitle: "Know how to do",
    text: [
      "Registered as a Publishing Company since 2012 , we’ve embraced every steps, responsibilities, tasks and assignments it takes to a Sub Publisher’s Job. Making your Songwriters and Rightholders creations rewarding, in complete transparency.",
      "Originated from the Electronic Music world we quickly became highly involved in Collection Societies at a political level, we’ve helped pushing boundaries in France to method regulations around new music usages.",
      "Recently rewarded by SACEM under the “French VIP 2019” prize and sitting a chair in the CSDEM committee are the common ground of what we call a mutually beneficial relationship, put to use for your Songwriters and your repertoire.",
    ],
    img: process.env.PUBLIC_URL + "/assets/ordiBuble.png",
    block1Title: "Unclaimed rights",
    block1Text: [
      "Royalties originates from so many sources (Airplay, DSP’s, Instore, Club..) it’s a known fact that 10 to 20% of your creation’s revenue is likely to end unclaimed.",
      "Any Collection Society will confirm the struggle it is to match the colossal amount of data processed, especially if the source contains missing or incorrect data.",
      "Our team masters the time consuming task of unclaimed royalties recovery, manually if necessary.",
    ],
    block2Title: "Administration",
    block2Cat: [
      {
        icon: process.env.PUBLIC_URL + "/assets/increase.svg",
        text: "Increase your current copyright royalties in France",
      },
      {
        icon: process.env.PUBLIC_URL + "/assets/graph.svg",
        text: "Spotlight your data with our royalty statement analysis",
      },
      {
        icon: process.env.PUBLIC_URL + "/assets/checkbox.svg",
        text: "Gain valuable time as we Administer and Register your work",
      },
    ],
    block3Title: "Performance",
    block3Text:
      "Are your songwriters live performers ? We can link that. Gigs are an amazing source of additional copyright revenues for your songwriters. Whether it’s a Live Performance or DJ Set we have the ability to track down every pending revenue following the venue on France territory.",
    block3Cat: [
      {
        icon: process.env.PUBLIC_URL + "/assets/record.svg",
        text: "Gain valuable copyright revenue from live performances & Dj Sets.",
      },
      {
        icon: process.env.PUBLIC_URL + "/assets/globe.svg",
        text: "Rely on many years of expertise and collaboration with SACEM, on National and Local level.",
      },
    ],
    block3Img: process.env.PUBLIC_URL + "/assets/performance.png",
  },
  sync: {
    title: "Sync Licensing",
    text: "We’re used to administer and negotiate SYNC usages of all shapes, forms, territory and duration on your behalf. Take part of our monthly SYNC Pitches.",
    button_title: "Library",
    buttons: {
      img: process.env.PUBLIC_URL + "/assets/disco.jpg",
      url: "https://kaaproduction.disco.ac/lib/3934",
    },
    media: [
      "https://www.youtube.com/watch?v=I4rLyRsFr6g&t=13s&ab_channel=Citro%C3%ABnFrance",
      "https://www.youtube.com/watch?v=Djk09wZcD00&ab_channel=SERGEBLANCO",
      "https://www.youtube.com/watch?v=oy-UBQCVj2c&ab_channel=Cash4Songs",
      "https://www.youtube.com/watch?v=vtExACnYmKs&t=20s&ab_channel=VanCleef%26Arpels",
      "https://www.youtube.com/watch?v=4_UNghXWBDY&ab_channel=CHANEL",
      "https://www.youtube.com/watch?v=oU19lOjgKa8&ab_channel=MaximeTisn%C3%A9-Versailles",
      "https://www.youtube.com/watch?v=KvWKW6yzC8Y",
    ],
    media2: [
      {
        img: process.env.PUBLIC_URL + "/assets/hermesMedia.png",
        url: "https://www.hermes.com/fr/fr/story/275988-cadeaux-femmes/",
      },
      {
        img: process.env.PUBLIC_URL + "/assets/agnesbxspringcourt.jpg",
        url: "https://www.facebook.com/agnesb.officiel.fr/videos/308056596576921/",
      },
    ],
    media3: [
      "https://player.vimeo.com/video/319924258",
      "https://player.vimeo.com/video/302466731",
      "https://player.vimeo.com/video/375414342",
      "https://player.vimeo.com/video/167899842",
      "https://player.vimeo.com/video/467665417",
      "https://player.vimeo.com/video/461998466",
      "https://player.vimeo.com/video/425942882",
      "https://player.vimeo.com/video/383565849",
    ],
    subTitle: "Creative",
    carousel: [
      {
        url: "https://s.disco.ac/fndnfcsewoya",
        name: "Maxime Tisné-Versailles",
        img:
          process.env.PUBLIC_URL +
          "/assets/artists/Maxime Tisné-Versailles.jpg",
      },
      {
        url: "https://s.disco.ac/vlskxhwgjqlj",
        name: "Fréderic Faupin",
        img: process.env.PUBLIC_URL + "/assets/artists/Fréderic Faupin.jpg",
      },
      {
        url: "https://s.disco.ac/pgjofzapgisd",
        name: "Benjamin Cholet",
        img: process.env.PUBLIC_URL + "/assets/artists/Benjamin Cholet.jpg",
      },
      {
        url: "",
        name: "Lionel Bousquet",
        img: process.env.PUBLIC_URL + "/assets/artists/Lionel Bousquet.jpg",
      },
      {
        url: "https://s.disco.ac/pzngijzirfym",
        name: "Yann Rouquet",
        img: process.env.PUBLIC_URL + "/assets/artists/Yann Rouquet.jpg",
      },
    ],
  },
  studio: {
    title: "Studio",
    subTitle: "Services",
    text: "We’re offering a wide range of services to help you to be as creative and productive as possible while in our complete and coherent facilities.",
    cat: [
      {
        icon: process.env.PUBLIC_URL + "/assets/record.svg",
        text: "Sound Recording",
      },
      {
        icon: process.env.PUBLIC_URL + "/assets/editing.svg",
        text: "Editing",
      },
      {
        icon: process.env.PUBLIC_URL + "/assets/volume.svg",
        text: "Sound Design",
      },
      {
        icon: process.env.PUBLIC_URL + "/assets/panele.svg",
        text: "Mix",
      },
    ],
    img: process.env.PUBLIC_URL + "/assets/studioImg.jpg",
    subSubTitle: "Studio",
    subSubtext:
      "Our main Studio 1 was built following ITU standarts for all Broadcast & TV requirements.",
    but: [
      {
        butTitle: "Studio 1",
        title: "Mix & Sound Recording",
        img: [
          {
            img: process.env.PUBLIC_URL + "/assets/studio/studio1-large.jpg",
            alt: "studio 1",
          },
          {
            img: process.env.PUBLIC_URL + "/assets/studio/studio2-large.jpg",
            alt: "studio 2",
          },
          {
            img: process.env.PUBLIC_URL + "/assets/studio/studio3-large.jpg",
            alt: "studio 3",
          },
        ],
        cat: [
          {
            title: "Sound System",
            subTitle: "GENELEC 8040A, GENELEC Sub 7270A",
          },
          {
            title: "Console",
            subTitle: "AVID Artist Control, AVID Artist Mix",
          },
          {
            title: "Daw",
            subTitle: "Mac Pro & ProTools Ultimate, HDX AVID 196-IO",
          },
          {
            title: "Gear",
            subTitle: "NEUMAN TLM103, Focusrite S. ISA430",
          },
          {
            title: "Others",
            subTitle:
              "Dolby Digital DP569 Encoder, Dolby Digital DP562 Decoder, DTS-CAD5 Decoder, Display LCD Sony 55",
          },
        ],
      },
      {
        butTitle: "Studio 2",
        title: "Mix",
        img: [
          {
            img: process.env.PUBLIC_URL + "/assets/studio/studio4-large.jpg",
            alt: "studio 4",
          },
          {
            img: process.env.PUBLIC_URL + "/assets/studio/studio5-large.jpg",
            alt: "studio 5",
          },
          {
            img: process.env.PUBLIC_URL + "/assets/studio/studio9.jpg",
            alt: "studio 9",
          },
        ],
        cat: [
          {
            title: "Sound System",
            subTitle: "GENELEC 8040A",
          },
          {
            title: "DAW",
            subTitle: "ProTools HDX, AVID OMNI, AVID SYNC HD",
          },
        ],
      },
    ],
  },
  contact: {
    title: "Get in touch",
    text: "We love to hear your thoughts, suggestions, feedbacks or anything.",
    cat: [
      {
        icon: process.env.PUBLIC_URL + "/assets/phone.svg",
        text: "+33 (0) 652 932 404",
      },
      {
        icon: process.env.PUBLIC_URL + "/assets/mail.svg",
        text: "info@kaaproduction.com",
      },
      {
        icon: process.env.PUBLIC_URL + "/assets/map.svg",
        text: "72 Boulevard de la Marquette Toulouse 31000",
      },
    ],
    label: ["Name", "E-Mail", "Your Request", "Subject"],
    textareaLabel: "Your Message",
    radioText:
      "By submitting, you consent to us using your mail address to answer to your request. You will not receive newsletters or any commercial information.",
    butText: "Submit",
  },
  pub: [
    [
      {
        url: "https://www.upfi.fr/",
        img: process.env.PUBLIC_URL + "/assets/upfilogo.png",
      },
      {
        url: "http://www.sppf.com/",
        img: process.env.PUBLIC_URL + "/assets/sppf.png",
      },
    ],
    [
      {
        url: "https://cnm.fr/",
        img: process.env.PUBLIC_URL + "/assets/cnm-logo2022.png",
      },
      {
        url: "https://sacem.fr/",
        img: process.env.PUBLIC_URL + "/assets/sacem.png",
      },
      {
        url: "https://csdem.org/",
        img: process.env.PUBLIC_URL + "/assets/csdem-resize.png",
      },
    ],
    [
      {
        url: "https://www.casinosbarriere.com/fr/toulouse.html",
        img: process.env.PUBLIC_URL + "/assets/casino.png",
      },
      {
        url: "https://www.masterfilms.fr/",
        img: process.env.PUBLIC_URL + "/assets/masterfilm.png",
      },
      {
        url: "https://xbofilms.com/",
        img: process.env.PUBLIC_URL + "/assets/xbofilms.png",
      },
      {
        url: "https://www.couleur-citron.com/",
        img: process.env.PUBLIC_URL + "/assets/couleurcitron.png",
      },
      {
        url: "https://motionpalace.tv/",
        img: process.env.PUBLIC_URL + "/assets/motionpalace.png",
      },
      {
        url: "https://www.digivision.fr/",
        img: process.env.PUBLIC_URL + "/assets/digivision.png",
      },
      {
        url: "https://www.morgane-groupe.fr/",
        img: process.env.PUBLIC_URL + "/assets/morgane-prod-1.png",
      },
      {
        url: "https://anoki.fr/",
        img: process.env.PUBLIC_URL + "/assets/anoki.png",
      },
      {
        url: "http://www.lelokalproduction.com/",
        img: process.env.PUBLIC_URL + "/assets/lelokal.png",
      },
    ],
  ],
  terms: {
    nav: "Back home",
  },
};

export default contentEN;
