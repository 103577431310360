import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";

import "react-responsive-carousel/lib/styles/carousel.min.css";

const StudioCar = ({ content }) => {
  const [toggled, setToggled] = useState(0);

  return (
    <>
      <div className="w-full overflow-hidden relative">
        <Carousel
          className="hidden md:block"
          autoPlay={true}
          infiniteLoop={true}
          interval={4000}
          showArrows={false}
          showStatus={false}
          showIndicators={false}
          showThumbs={false}
        >
          {content.studio.but[toggled].img.map((item, index) => {
            return (
              <img
                style={{ minHeight: "900px" }}
                className="object-cover"
                key={index}
                src={item.img}
                alt={item.alt}
              />
            );
          })}
        </Carousel>
        <div className="md:absolute z-10 md:top-0 w-full flex">
          <div className="m-auto mt-10">
            {content.studio.but.map((item, index) => {
              return (
                <button
                  aria-label="Toggle studio index"
                  onClick={() => setToggled(index)}
                  className={`text-xs md:text-base font-semibold m-3 px-2 md:px-5 py-3 outline-none focus:outline-none ${
                    toggled === index
                      ? "bg-white border-2 border-gray-900 text-gray-900 rounded-3xl"
                      : "border-2 border-gray-800 bg-gray-800 text-white rounded-3xl transition-all duration-300 ease-out hover:bg-white hover:text-gray-800"
                  }`}
                  key={index}
                >
                  {item.butTitle}
                </button>
              );
            })}
          </div>
        </div>
        <div
          style={{
            backgroundImage:
              "linear-gradient(210deg, transparent, rgba(0, 0, 0, 0.60), #141414)",
          }}
          className="hidden md:block absolute top-0 left-0 w-full h-full text-white"
        >
          <div className="absolute bottom-0 p-16 left-0">
            <div className="m-auto">
              <p className="text-xl lg:text-4xl font-medium mb-3 md:mb-1">
                {content.studio.but[toggled].title}
              </p>
              <div className="mt-10 md:mt-5">
                {content.studio.but[toggled].cat.map((item, index) => {
                  return (
                    <div key={index}>
                      <p className="text-base lg:text-2xl font-medium mt-2 lg:mt-8 md:mt-5">
                        {item.title}
                      </p>
                      <hr className="my-1 lg:my-3 w-24" />
                      <p className="text-sm lg:text-base">{item.subTitle}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="md:hidden w-full h-full flex">
        <div className="p-16 left-0">
          <div className="m-auto">
            <p className="text-xl lg:text-4xl font-medium mb-3 md:mb-1">
              {content.studio.but[toggled].title}
            </p>
            <div className="mt-10 md:mt-5">
              {content.studio.but[toggled].cat.map((item, index) => {
                return (
                  <div key={index}>
                    <p className="text-base lg:text-2xl font-medium mt-2 lg:mt-8 md:mt-5">
                      {item.title}
                    </p>
                    <hr className="my-1 lg:my-3 w-24" />
                    <p className="text-sm lg:text-base">{item.subTitle}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudioCar;
