import React, { useState } from "react";
import { ReactNotifications } from 'react-notifications-component'
import { Switch, Route } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import Banner from "./components/Banner";
import Navbar from "./components/Navbar";
import Header from "./components/Header";
import Publishing from "./components/Publishing";
import Sync from "./components/Sync";
import Production from "./components/Production";
import Studio from "./components/Studio";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import SponLogos from "./components/SponLogos";

import contentEN from "./content/contentEN";
import contentFR from "./content/contentFR";

import "react-notifications-component/dist/theme.css";

const App = () => {
  const [language, setLanguage] = useState("EN");

  return (
    <>
      <ReactNotifications />
      <div>
        <Navbar
          content={language === "EN" ? contentEN : contentFR}
          setLanguage={setLanguage}
        />
        <Switch>
          <Route exact path="/">
            <Home language={language} />
          </Route>
          <Route exact path="/terms_of_service">
            <Terms language={language} />
          </Route>
        </Switch>
        <Footer
          content={language === "EN" ? contentEN : contentFR}
          setLanguage={setLanguage}
        />
      </div>
    </>
  );
};

export default App;

const Home = ({ language }) => {
  return (
    <>
      <Banner content={language === "EN" ? contentEN : contentFR} />
      <Header content={language === "EN" ? contentEN : contentFR} />
      <Production content={language === "EN" ? contentEN : contentFR} />
      <SponLogos content={language === "EN" ? contentEN : contentFR} nb="0" />
      <Publishing content={language === "EN" ? contentEN : contentFR} />
      <SponLogos content={language === "EN" ? contentEN : contentFR} nb="1" />
      <Sync content={language === "EN" ? contentEN : contentFR} />
      <Studio content={language === "EN" ? contentEN : contentFR} />
      <SponLogos content={language === "EN" ? contentEN : contentFR} nb="2" />
      <Contact content={language === "EN" ? contentEN : contentFR} />
    </>
  );
};

const Terms = ({ language }) => {
  return (
    <div
      style={{
        paddingLeft: "10%",
        paddingRight: "10%",
        paddingTop: "5%",
        paddingBottom: "5%",
      }}
      className="min-h-screen mt-16"
    >
      {language === "EN" && (
        <div>
          <h1 className="text-5xl font-extrabold mb-5">Privacy Policy</h1>
          <hr className="w-2/12 mb-10" />
          <p>
            KAA production operates the{" "}
            <a href="/" className="text-gray-600">
              http://kaaproduction.com
            </a>{" "}
            website. <br />
            <br />
            This page informs you of our policies regarding the collection, use,
            and disclosure of personal data when you use our website and the
            choices you have associated with that data.
          </p>
          <h2 className="text-3xl font-bold my-5">
            Information Collection And Use
          </h2>
          <h3 className="text-2xl font-bold my-4">Cookies</h3>
          <p>
            By visiting our website (in connection with other activities,
            services, features or resources) and using our Contact form
            (submitting voluntarily informations to us), you agree to the
            collection and use of information in accordance with this policy. By
            submitting, you consent to us using your mail address to answer to
            your request. You will not receive newsletters or any commercial
            information.
            <br />
            <br />
            Cookies are files with small amount of data which may include an
            anonymous unique identifier. Cookies are sent to your browser from a
            website and stored on your device.
            <br />
            <br />
            You can prevent the installation of cookies via the relevant setting
            in your browser software. However, if you do not accept cookies, you
            may not be able to use some portions of the website.
            <br />
            <br />
            You can configure your cookies settings by blocking third-party
            cookies, or using plugins like DoNotTrackMe to prevent the
            installation of web tracers.
          </p>
          <h3 className="text-2xl font-bold my-4">Personal Data</h3>
          <p>
            When you contact us, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you. Personally identifiable information may include, but
            is not limited to:
            <br />
            <br />
          </p>
          <p className="pl-5">
            - Email address
            <br />- First name and last name
          </p>
          <h3 className="text-2xl font-bold my-4">Links To Other Sites</h3>
          <p>
            Our website may contain links to other sites that are not operated
            by us. If you click on a third party link, you will be directed to
            that third party’s site. We strongly advise you to review the
            Privacy Policy of every site you visit.
            <br />
            <br />
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </p>
          <h2 className="text-3xl font-bold my-5">
            Use & Transfer Of Your Datas
          </h2>
          <h3 className="text-2xl font-bold my-4">Rights On Your Datas</h3>
          <p>
            Your information, including Personal Data, may be transferred to —
            and maintained on — computers located outside of your state,
            province, country or other governmental jurisdiction where the data
            protection laws may differ than those from your jurisdiction.
            <br />
            <br />
            If you are located outside France and choose to provide information
            to us, please note that we transfer the data, including Personal
            Data, to France and process it there.
            <br />
            <br />
            Your consent to this Privacy Policy followed by your submission of
            such information represents your agreement to that transfer.
            <br />
            <br />
            KAA Production will take all steps reasonably necessary to ensure
            that your data is treated securely and in accordance with this
            Privacy Policy and no transfer of your Personal Data will take place
            to an organization or a country unless there are adequate controls
            in place including the security of your data and other personal
            information.
          </p>
          <h2 className="text-3xl font-bold my-5">
            Changes To This Privacy Policy
          </h2>
          <p>
            We may update our Privacy Policy from time to time. We encourage the
            users to frequently check this page for any changes to stay informed
            about how we protect informations we collect.
            <br />
            <br />
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
          <h3 className="text-2xl font-bold my-4">Contact Us</h3>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us:
            <br />
            <br />
            By visiting this page on our website:{" "}
            <HashLink to="/#contact" className="text-gray-600">
              Contact
            </HashLink>
            <br />
            <br />
            By Mail:
            <br />
            <br />
          </p>
          <p className="pl-5">
            - 72 Boulevard de la Marquette
            <br />- KAA Production
            <br />- Toulouse 31000
            <br />- France
          </p>
        </div>
      )}
      {language === "FR" && (
        <div>
          <h1 className="text-5xl font-extrabold mb-5">
            Politique de Confidentialité
          </h1>
          <hr className="w-2/12 mb-10" />
          <p>
            L’adresse de notre site Web est :{" "}
            <a href="/" className="text-gray-600">
              http://kaaproduction.com
            </a>
            <br />
            <br />
            Notre Politique de Confidentialité décrit l’usage que KAA Production
            fait des informations collectées vous concernant lorsque vous
            utilisez notre site. Elle vous permet de connaître l’origine et
            l’usage des informations de navigation collectées à l’aide de
            cookies, ainsi que de connaître les droits dont vous disposez en
            application de la législation applicable en matière de protection
            des données personnelles.
          </p>
          <h2 className="text-3xl font-bold my-5">
            Utilisation des données personnelles collectées
          </h2>
          <h3 className="text-2xl font-bold my-4">Cookies</h3>
          <p>
            Si vous nous contactez via le formulaire de Contact de notre site,
            il vous sera proposé d’enregistrer votre nom, adresse de messagerie
            et message dans des cookies. C’est uniquement pour votre confort
            afin de ne pas avoir à saisir ces informations si vous nous
            contactez plus tard. Ces cookies expirent au bout d’un an.
            <br />
            <br />
            Si vous vous connectez sur notre site, un cookie temporaire sera
            créé afin de déterminer si votre navigateur accepte les cookies. Il
            ne contient pas de données personnelles et sera supprimé
            automatiquement à la fermeture de votre navigateur. De plus, nous
            mettrons en place un certain nombre de cookies pour enregistrer vos
            informations de connexion et vos préférences d’écran. La durée de
            vie d’un cookie de connexion est de deux jours, celle d’un cookie
            d’option d’écran est d’un an.
            <br />
            <br />
            Il est possible de modérer ou empêcher l’installation de ces cookies
            via les paramètres de votre navigateur. Cependant, sans leur
            installation, vous ne pourrez pas profiter pleinement de notre site.
          </p>
          <h3 className="text-2xl font-bold my-4">Données personnelles</h3>
          <p>
            Lorsque vous nous contactez, vous consentez à ce que nous utilisions
            les données personnelles suivantes pour répondre à votre question.
            Aucune information ou publicité à but commercial et/ou newsletter ne
            vous sera envoyée. Ces données personnelles incluent :
            <br />
            <br />
          </p>
          <p className="pl-5">
            - Nom et prénom
            <br />- Adresse mail
          </p>
          <h3 className="text-2xl font-bold my-4">
            Contenu embarqué depuis d’autres sites
          </h3>
          <p>
            Les pages de ce site peuvent inclure des contenus intégrés (par
            exemple des vidéos, images, articles…). Le contenu intégré depuis
            d’autres sites se comporte de la même manière que si le visiteur se
            rendait sur cet autre site.
            <br />
            <br />
            Ces sites web pourraient collecter des données sur vous, utiliser
            des cookies, embarquer des outils de suivis tiers, suivre vos
            interactions avec ces contenus embarqués si vous disposez d’un
            compte connecté sur leur site web. Vous pouvez modifier à tout
            moment vos paramètres et préférences concernant les cookies via le
            Quantcast GDPR Manager utilisé en coin sur notre site.
          </p>
          <h2 className="text-3xl font-bold my-5">
            Utilisation et transmission de vos données personnelles
          </h2>
          <h3 className="text-2xl font-bold my-4">
            Les droits que vous avez sur vos données
          </h3>
          <p>
            Si vous avez laissé un message via notre site, vous pouvez demander
            à recevoir un fichier contenant toutes les données personnelles que
            nous possédons à votre sujet, incluant celles que vous nous avez
            fournies. Vous pouvez également demander la suppression des données
            personnelles vous concernant. Cela ne prend pas en compte les
            données stockées à des fins administratives, légales ou pour des
            raisons de sécurité.
          </p>
          <h2 className="text-3xl font-bold my-5">Modifications</h2>
          <p>
            Nous sommes susceptibles de modifier notre Politique de
            Confidentialité. Nous vous encourageons à la consulter régulièrement
            pour rester informés de nos pratiques en matière de protection et de
            contrôle des données personnelles.
          </p>
          <h3 className="text-2xl font-bold my-4">Contactez-nous</h3>
          <p>
            Si vous avez des questions à propos de cette Politique de
            Confidentialité, n’hésitez pas à nous en faire part :
            <br />
            <br />
            Via notre formulaire de{" "}
            <HashLink to="/#contact" className="text-gray-600">
              Contact
            </HashLink>
            <br />
            <br />
            Par courrier :
            <br />
            <br />
          </p>
          <p className="pl-5">
            - 72 Boulevard de la Marquette
            <br />- KAA Production
            <br />- Toulouse 31000
            <br />- France
          </p>
        </div>
      )}
    </div>
  );
};
