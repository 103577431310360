import React from "react";
import { Carousel } from "react-responsive-carousel";

import "react-responsive-carousel/lib/styles/carousel.min.css";

const SponLogos = ({ content, nb }) => {
  return (
    <div
      style={{ background: "#F6F6F6" }}
      className="w-full py-3 hidden md:block"
    >
      <div className="flex w-9/12 xl:w-1/3 m-auto my-5">
        {nb !== "2" ? (
          content.pub[parseInt(nb, 10)].map((item, index) => {
            return (
              <div
                key={index}
                className="m-auto mx-5 opacity-50 transition duration-300 xl:hover:opacity-100"
              >
                <a href={item.url} rel="noreferrer" target="_blank">
                  <img className="" src={item.img} alt="icons" />
                </a>
              </div>
            );
          })
        ) : (
          <Carousel
            className="w-full"
            autoPlay={true}
            infiniteLoop={true}
            interval={4000}
            showArrows={false}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            centerMode={true}
            centerSlidePercentage={33.5}
          >
            {content.pub[parseInt(nb, 10)].map((item, index) => {
              return (
                <div
                  key={index}
                  className={`m-auto opacity-50 transition duration-300 xl:hover:opacity-100 ${
                    index !== 0 &&
                    index !== content.pub[parseInt(nb, 10)].length &&
                    "px-5"
                  }`}
                >
                  <a href={item.url} rel="noreferrer" target="_blank">
                    <img src={item.img} alt="icons" />
                  </a>
                </div>
              );
            })}
          </Carousel>
        )}
      </div>
    </div>
  );
};

export default SponLogos;
